/*Grid*/
.grid {
    max-width: @desktop-container !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding-left: @container-padding;
    padding-right: @container-padding;
    position: relative;
}

// ==========================================================================
// Desktop Custom Styles
// ==========================================================================

@import url('https://fonts.googleapis.com/css?family=Cormorant+Garamond|Lato');

/* ---------------------
 Body
 --------------------- */

html,
body {
    font-weight: 300;
	/*overflow-x: hidden;*/
    background-color: @color-body;
}

.wsmenucontainer{background: @color-body;} //sets full site background color

main ol, ul {
    padding: 0 40px;
    
    l1:after {
    margin-bottom:20px;	
    }
}

/* ---------------------
    Subpage
 --------------------- */
.subpage {
    .under-banner {height: 0.0625rem;}
    .pagename h2, .description h2 {font-size:250%; text-transform: uppercase; color: @color-primary;}
    .sub-header { background: #FFF; border-bottom: 0.0625rem solid @color-line;}
    
}

/* ---------------------
    ADA
 --------------------- */
.ada {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
    color:#fff;
    background: #000;
}

@media only screen and (max-width: 1600px){
    .header-quicklinks .ada-contrast a span{display:none;}
}

#skiptocontent a {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-bottom: 1px solid #ffffff;
    border-bottom-right-radius: 8px;
    border-right: 1px solid #ffffff;
    color: @color-navbar-text;
    background: @color-navbar;
    left: 0;
    padding: 6px;
    position: absolute;
    top: -40px;
    transition: top 1s ease-out 0s, background 1s linear 0s;
    z-index: 100;
}
#skiptocontent a:focus {
    background: #bf1722 none repeat scroll 0 0;
    left: 0;
    outline: 0 none;
    position: absolute;
    top: 0;
    transition: top 0.1s ease-in 0s, background 0.5s linear 0s;
}
//ADA

.rspPane div#broadcastMessageHolder {
    display: none;
}

::selection {background: @color-primary; color: #FFF;}
::-moz-selection {background: @color-primary; color: #FFF;}


.shifter {position: relative; width: 100%; left: 0; }
.shifter-overlay {position: absolute; top:0; bottom: 0; left: 0; right: 0; background: rgba(0,0,0,0.45); z-index: 20000; display: none;}

.media-state {display: none; position: absolute; height: 0; width: 0; right: 0; bottom: 0; z-index: 3;}

#skiptocontent a {
    background: #000 none repeat scroll 0 0;
    border-bottom: 1px solid #ffffff;
    border-bottom-right-radius: 8px;
    border-right: 1px solid #ffffff;
    color: #ffffff;
    left: 0;
    padding: 6px;
    position: absolute;
    top: -40px;
    transition: top 1s ease-out 0s, background 1s linear 0s;
    z-index: 100;
}
#skiptocontent a:focus {
    background: #bf1722 none repeat scroll 0 0;
    left: 0;
    outline: 0 none;
    position: absolute;
    top: 0;
    transition: top 0.1s ease-in 0s, background 0.5s linear 0s;
}

/* ---------------------
 Universal
 --------------------- */

.view-more {
    display: inline-block;
    position: relative;
    font-size: 12px;
    font-size: 0.75rem;
    color: inherit;
    .transition;
    &:after {
        font-family: FontAwesome;
        content: "\f178";
        color: @color-primary;
        display: inline-block;
        margin: 0 0 0 .5rem;
        position: relative;
        top: 1px;
    }
    
    &:hover {color: @color-primary;}
}

.icon-title {
    display: inline-block;
    font-weight: 700;
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: inherit;
    
    &:before {
        display: inline-block;
        font-family: FontAwesome;
        font-weight: 400;
        margin: 0 .8rem 0 0;
        color: @color-primary;
        position: relative;
    }
}

.icon-title-small {
    .icon-title;
    font-size: 18px;
    font-size: 1.125rem;
    &:before { margin: 0 0.5rem 0 0; }
}

.view-all {
    position: relative;
    font-size: 14px;
    font-size: 0.875rem;
    top: -3px;
    margin-left: 1.5rem;
    padding: 0.25rem 0 0.25rem 1.5rem ;
    text-transform: uppercase;
    color: @color-primary !important;
    border-left: 1px solid #CCC;
    .transition;
    &:hover {color: inherit !important;}
}

.customTitle {
    display: inline-block;
    font-weight: 700;
    font-size: 24px;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    color: inherit;
    
    &.enabledIcon {
        .ctIconLeft {
            width: 1.5rem;
            height: 1.5rem;
            top: -1px;
            display: inline-block;
            vertical-align: middle;
            background-size: cover;
            position: relative;
            margin-right: 0.375rem;
        }
    }
}

.under-banner {
    background: #262626;
    height: 0.25rem;
}

.bottomnav {
    text-align: center;
    ul {list-style-type: none; padding: 0;}
    li { display: inline-block; }
    li+li:before {content :"|"; padding: 0 0.625rem;}
}

/* ---------------------
	 Topbar
	 --------------------- */
.topbar {
    background: #024b82;
 
    .site-title {
        font-weight: 100;
        margin: 0;
        display: inline-block;
        text-transform: none;
        font-size: 40px;
        line-height: 1; 
    }
    
    .topbar-content {display: table; width: 100%; margin-bottom: 0%;}
    
    .logo-container,
    .logo-text-container,
    .round-link-container {display: table-cell; }
    
    .logo-container {width: 15%; position: relative; 
        .logo {
                position: inherit;
                display: block;
                max-width: 150px;
                width: 100%;
                z-index: 5;
                top: 0;

                img {max-height:165px; display: block; max-width: 100%; margin: 5px auto 0;}
            }
    }
    .logo-text-container {width: 42.5%; vertical-align: middle; padding: 2%;}
    .round-link-container {width: 42.5%;}
    
    .round-links {
        padding: 15px 0;
        width: 100%;
        li {
            display: inline-block;
            width: 24%;
        }
        a {
            display: block;
            border: 1px solid #bbc0c7;
            border-radius: 50%;
            height: 86px;
            width: 86px;
            margin: 0 auto;
            text-align: center;
            font-size: 11.5px;
            font-weight: 600;
            color: #262c36;
            background-color: #fff;
            padding: 20px 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            
            .transition;
            
            &:hover {
                border-color: @color-primary;
                background-color: @color-secondary;
                color: #fff;
                -moz-box-shadow: 0 0 10px 1px rgba(1,29,58,.3);
                -webkit-box-shadow: 0 0 10px 1px rgba(1,29,58,.3);
                box-shadow: 0 0 10px 1px rgba(1,29,58,.3);
            }
        }
        i {
            font-size: 24px;
            display: block;
            color: @color-primary;
        }
    }

    .hidden {
        display: none;
    }


}// end topbar


/* ---------------------
     Header Quicklinks
     ---------------------*/

/*These styles enable the touch "toggle open" effect*/
.hover_effect1,
.hover_effect2,
.hover_effect3{
  visibility: visible !important;
}

.topbar-divider{/*this mixin is for all social links*/
    content: "|";
    color: rgba(255, 255, 255, 0.3);
    padding: 0px 10px;
}

.header-quicklinks {
    font-size: 12px;
    background: @color-primary;
    text-transform: uppercase;
    

    /*Header-Quicklinks Divider*/
    span.divider {
    color: rgba(255,255,255,.3);
    padding: 0px 10px;
    }
    
    /*Header-Quicklinks-Nomargin Divider*/
    span.divider-no-margin {
        color: rgba(255, 255, 255, 0.3);
        margin: 0 8px 0 -8px;
    }
    
    .dropdown {
        display: inline-block;
        margin: 0;
        padding: 0;
        letter-spacing: 0.5px;
        font-size: 100%;
        font-family: @font-primary;
        i {
            font-size: 20px;
            margin-right:2px;
            color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
        }
        > li {
            display: inline-block;
            position: relative;
            &:hover {
            }
            
        > li > a:after {
			content: "|";
			padding-right: 20px;
			padding-left: 20px;
			}
            
            a {
                padding: 0px 0px 0px 10px;
                display: inline-block;
                color: contrast(@color-primary, #ffffff, #111111);
                &:hover {
                    color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
                }
            }
            .school-dropdown {
                line-height: 40px;
            }
            
            .quicklinks ul li {

              color: @color-navbar-text;
              display: block;
              min-width: 160px;
              margin: 0px;
              white-space: nowrap;
                em{padding-right:15px;}
            }
            
            .call-map{
                position: absolute;
                top: 0px;
                left: 6px;
                span {
                padding: 12px;
                }
                a{
                    color: #fff;
                    text-decoration: none;
                }
                .contact-phone{
                    margin-left: 10px;
                }
                .contact-map {
                    margin-right: 5px;
                }

            }
        }
        .search-icon-mobile{display: block;}/*Secondary Search for Mobile*/
        
        div {
            position: absolute;
            visibility: hidden;
            background: fade(@color-navbar, 90%);
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
            a {
                color: @color-navbar-text;
                display: block;
                min-width: 160px;
                padding:0px 10px;
                margin:0px;
                white-space: nowrap;
                border-bottom: 1px solid lighten(@color-primary, 10%);
                &:hover {
                    background: lighten(@color-primary, 20%);
                    color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
                }
            }
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }
        
        #m2 { 
            position: absolute;
            white-space: nowrap;
            right: 0;
            background: fade(@color-navbar, 90%);
            border-radius: 2px;
            top: 100%;

            &:hover { display: block !important; }

            &:before {
                content: " ";
                display: block;
                width: 0;
                height: 0;
                border-bottom: 6px solid @color-primary;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                top: -6px;
                position: absolute;
                right: 75px;
            }

            input {
                display: inline-block;
                height: 34px;
                border: none;
                width: 190px;
                vertical-align: bottom;
            }

            button {
                display: inline-block;
                height: 34px;
                background: contrast(@color-primary, #ffffff, #111111);
                color: #666;
                border: none;
                vertical-align: bottom;
            }

            input:focus, button:focus {border: none; outline: none;}

        }
        #m3 {right:0px;
            &:before {
                content: " ";
                display: block;
                width: 0;
                height: 0;
                border-bottom: 6px solid @color-primary;
                border-left: 6px solid transparent;
                border-right: 6px solid transparent;
                top: -6px;
                position: absolute;
                right: 85px;
            }
        
        }
        
    } //dropdown
    
    ul {
        .list-unstyled;
        li {
            display: inline-block;
            line-height: 50px;
            .phone-main {
                text-decoration: underline;
            }
        }
        a {
            color: contrast(@color-primary, #ffffff, #111111);
            font-weight: 600;
            text-align: center;
            i {
                 color: contrast(@color-primary, #ffffff, #111111);
                .transition;
            }
            
            &:hover {
                 color: contrast(@color-secondary, #ffffff, #111111);
                i {
                     color: #FFF;
                }
            }
        } 
    }
    
    .resources {
        color: #FFF;
        
        span {
            font-weight: 300;
            margin-right: 15px;
            &:before {
                display: inline-block;
                font-family: "Material Icons";
                content: "\E7FB";
                color: @color-secondary;
                font-weight: 400;
                font-size: 18px;
                line-height: 0;
                position: relative;
                top: 5px;
                margin: 0 .5rem 0 0;
            }
        }
        a {
            text-transform: uppercase;
             color: #fff;
             
            &:hover {color: contrast(@color-secondary, #ffffff, #111111);}
            
        }
        .fa {font-size: 0.8125rem; line-height: inherit; color: #fff; margin-left: 5px;}
         color: contrast(@color-primary, #ffffff, #111111);
        
    }
    
    .util {
        float:right;
        .social-twitter:after{
            .topbar-divider
        }
        .social-facebook:after{
            .topbar-divider
        }
        .social-instagram:after{
            .topbar-divider
        }
        .social-youtube:after{
            .topbar-divider
        } 
        a.social-facebook:hover {
            text-decoration: none;
        }

        a.social-facebook:hover i {
            opacity: .4;
        }

        a.social-twitter:hover {
            text-decoration: none;
        }

        a.social-twitter:hover i {
            opacity: .4;
        }

        a.social-youtube:hover {
            text-decoration: none;
        }

        a.social-youtube:hover i {
            opacity: .4;
        }

        a.social-instagram:hover {
            text-decoration: none;
        }

        a.social-instagram:hover i {
            opacity: .4;
        }


        a.social-linkedin:hover {
            text-decoration: none;
        }

        a.social-linkedin:hover i {
            opacity: .4;
        }
        
        i {
            font-size: 20px;
            margin-right: 0px;
        }
    }



    
    .search-button {
        background: @color-primary;
        padding: 0 22px;
        margin-left: 10px;
        display: inline-block;
        color: #FFF;
        i {font-size: 175%;}
        &:hover {cursor: pointer;}
    }

    .search-bar {
        background: lighten(@color-primary, 10%);
        position: relative;
        padding: 0 50px 0 10px;
        line-height: 50px;
        z-index: 99;
        width: auto;
        label{
            display: block;
            font-family: @font-primary;
            text-transform: uppercase;
        }
        i {
            font-size: 175%;
            margin: 0 10px;
            opacity: 0.7;
            .transition;
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }

        input {
            font-family: @font-primary;
            text-transform: uppercase;
            font-size: 12px;
            width: 100%;
            border: none;
            background: transparent;
            color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
            outline: none;
            
            &::placeholder{color:contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));}
        }

        button {
            background: transparent;
            color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
            padding: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 100%;
        }
    }

}

//Search
.search {
    position: relative;
    margin-left: 30px;
    float: right;

    & > a {
        display: inline-block; 
        color: #FFF; 
        height: 42px;
        line-height: 42px;
        text-align: center;
        font-size: 0.6875rem;
        text-transform: uppercase;
        vertical-align: middle;
        outline: none;
        border: none;

        i {
            color:@color-primary;
            font-size: 1rem;
            margin-right: 10px;
            vertical-align: middle;
            margin-top: -3px;
        }


//        &:focus, &:hover {
//            & + div {display: block !important;}
//        }

        &.open + div {display: block !important;}
        
        &:hover i { 
            color: @color-secondary;
        }
    }

    #m3 { 
        display: none;
        position: absolute;
        white-space: nowrap;
        right: 0;
        padding: 5px;
        background: @color-primary;
        border-radius: 2px;
        top: 100%;

        &:hover { display: block !important; }

        &:before {
            content: " ";
            display: block;
            width: 0;
            height: 0;
            border-bottom: 6px solid @color-primary;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            top: -6px;
            position: absolute;
            right: 20px;
        }

        input {
            display: inline-block;
            height: 34px;
            border: none;
            width: 190px;
            vertical-align: bottom;
        }

        button {
            display: inline-block;
            height: 34px;
            background: #FFF;
            color: #666;
            border: none;
            vertical-align: bottom;
        }

        input:focus, button:focus {border: none; outline: none;}

    }

}


/* ---------------------
	 Header
	 --------------------- */

.homepages.non-teachers .header {
  position: absolute;
  width: 100%;
  z-index: 12;
}

.subpage{
    .banner {margin-bottom:20px;}
	.header {
    position: relative;
    }
}

.rspPane .subpage .banner {margin-bottom:50px;}

.teachers .header .header-title h1 {
  margin-top: 25px;
  text-transform: none !important;
}

.header {
  background-color: fade(@color-body, 70%);
  position: relative;
  color: @color-primary;

    .header-bg {
      bottom: 0;
      display: inline;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
    .header-bg-img {
      bottom: 0;
      left: 0;
      opacity: 0.2;
      position: absolute;
      right: 0;
      top: 0;
    }
    a {
      color: #fff;
    }
    .header-title {
      display: block;
      /*max-width: 220px;*/
        
        .logo,
        .title {
          display: block;
          float: left;
        }
        .logo {
          text-align: center;
            img {
              max-height: 145px;
              max-width: 280px;
              margin: 15px 0px ;
              float:left;
            }
        }

        h1.title {
          display: inline-block;
          .checkLogo; //hides the title if the logo contains the name
          font-family: @font-secondary;
          font-size: 300%;
          color: contrast(@color-body, @color-primary, #ffffff);
          line-height: 0.75;
          margin: 23px auto 10px;
          padding: 5px 15px;
          text-align: left;
            
            span.tagline {
                color: contrast(@color-body, @color-secondary, #ffffff);
                font-family: @font-primary;
                display:  block;
                font-size: 60%;
                padding: 10px 0px;
            }
            
        }

        .tagline-contained {
          list-style: outside none none;
          margin: 0;
          padding: 0;
            
            .tagline {
              text-align: center;
              font-style: italic;
              border-top: 2px solid #c9993d;
              display: table;
              font-family: @font-primary;
              font-size: 100%;
              margin: 5px auto;
              padding-bottom: 10px;
              padding-top: 9px;
                
                &::before,
                &::after {
                  content: "\"";
                }
            }
        }//tagline-contained

    .header-title .ada-contrast {
        display:  none;
    }//secondary ADA tools for tablet view
        
    .contact {
      border-top: 2px solid #c9993d;
      display: table;
      font-family: @font-primary;
      font-size: 100%;
      margin: 5px auto;
      padding-bottom: 10px;
      padding-top: 9px;
        
        i {color: #c9993d;}
        li {
          display: inline;
          margin-right: 5px;
        }
        
        a {
            text-decoration: underline;
            &:hover {text-decoration: none;}
        }
        
        }//contact
        
    }//header-title

    #headerlinks,
    #teacher-headerlinks {
      margin-top: 1.3em;
      font-size: 21px;
      font-family: @font-primary;
      line-height: 1.7em;
      position: relative;
      width: 171px;
    }

      /* ---------------------
               School header links
               --------------------- */

    #headerlinks i,
    #teacher-headerlinks i {
      margin-left: 10px;
      font-size: 120%;
      float: right;
    }
    #headerlinks a,
    #teacher-headerlinks a,
    #headerlinks i,
    #teacher-headerlinks i {
      filter: alpha(opacity=80);
      opacity: 0.8;
      text-align: center;
    }
    #headerlinks a:hover,
    #teacher-headerlinks a:hover,
    #headerlinks i:hover,
    #teacher-headerlinks i:hover {
      opacity: 1;
      filter: alpha(opacity=100);
    }
    #headerlinks li,
    #teacher-headerlinks li {
      font-size: 75%;
      padding-left: 6px;
      width: auto;
    }
    #headerlinks .fa,
    #teacher-headerlinks .fa {
      display: block;
    }
    #headerlinks .powerschool,
    #teacher-headerlinks .powerschool {
      background: rgba(0, 0, 0, 0) url("../images/logo-powerschool.png") no-repeat scroll center center / 100% auto !important;
      height: 16px !important;
      width: 11px !important;
    }
    #headerlinks .google-classroom,
    #teacher-headerlinks .google-classroom {
      background: rgba(0, 0, 0, 0) url("../images/google-classroom.png") no-repeat scroll center bottom / 100% auto !important;
      height: 18px !important;
      left: 5px;
      margin-left: 0;
      position: relative;
      width: 21px !important;
    }
    #headerlinks .aeries,
    #teacher-headerlinks .aeries {
      background: rgba(0, 0, 0, 0) url("../images/aeries.png") no-repeat scroll center bottom / 100% auto !important;
    }
    #headerlinks .classworks,
    #teacher-headerlinks .classworks {
      background: rgba(0, 0, 0, 0) url("../images/classworks.png") no-repeat scroll center bottom / 100% auto !important;
    }
    #headerlinks .clever,
    #teacher-headerlinks .clever {
      background: rgba(0, 0, 0, 0) url("../images/clever.png") no-repeat scroll center bottom / 100% auto !important;
    }
    #headerlinks .renaissanceLearning,
    #teacher-headerlinks .renaissanceLearning {
      background: rgba(0, 0, 0, 0) url("../images/renaissanceLearning.png") no-repeat scroll center bottom / 100% auto !important;
    }
    #headerlinks .aeries,
    #teacher-headerlinks .aeries,
    #headerlinks .classworks,
    #teacher-headerlinks .classworks,
    #headerlinks .clever,
    #teacher-headerlinks .clever,
    #headerlinks .renaissanceLearning,
    #teacher-headerlinks .renaissanceLearning {
      display: block;
      height: 20px;
      margin: -3px auto 0 10px;
      width: 17px;
    }
    .district-link {
      display: none !important;
    }

}//header

/* ---------------------
	 Navigation
	 ---------------------*/
.navbar {
    position: relative;
    float:right;
    border-radius: 0px !important;
    z-index:20;
    margin: 15px 0px 34px;
    &.sticky {
        bottom: auto !important;
        background: fade(@color-body, 98%);
        margin-top: 0px;
        .wsmenu ul ul ul {
            top: 0px;
        }
    }
    .wsmenu {
        font-family: @font-primary;
        text-transform: uppercase;
        font-weight: normal;
        margin-bottom: 0px;
        height:65px;
        &-list > li {
            &::before {
                bottom: 5px;
                color: contrast(@color-body, @color-tertiary, #ffffff);
                display: block;
                font-size: 28px;
                margin-left: -2px;
                position: absolute;
                top: -8px;
                z-index: 800;
            }
            &:first-of-type::before {
               display: none;
            }
   
        &:last-child  a {
            border-right: 0px;
            }  
        }
    }
}

/* --------------------
    Banner
-------------------- */
.banner{
    position: relative;
    overflow: hidden;
    
    aside {
        position:  absolute;
        top: 52%;
        right: 0px;
        
        a.sidelink {
            background: @color-primary;
            padding: 10px 0px;
            margin: 10px 0px;
            display: block;
            overflow:  hidden;
            position:  relative;
            right: -180px;
            left: 180px;
            transition:  all .2s;

           span {
                color: contrast(@color-primary, #ffffff, #111111);
                font-size: 100%;
                display: block;
                width: 100px;
                overflow:hidden;
                white-space:  nowrap;
                text-overflow: ellipsis;
                padding:5px;
                text-align: center;
                transition: all 0.2s ease-in-out;
            }
            
            .description {
                width: 180px;
                padding: 5px;
                color: contrast(@color-primary, #ffffff, #111111);
            }
            
            &:hover {
                width: inherit;
                left: 0;

                span {
                    color: contrast(@color-primary, #ffffff, #111111);
                    font-size: 100%;
                    text-indent: 0px;
                }
                
            }
            
        }
        
        img {
            display:  inline-block;
            width:100px;
            height:62px;
            padding: 5px;
        }
        
    }/*aside*/
}/*banner*/

/*Catapult UI Adjustments*/
.rspPane .banner aside{width:300px;}
.rspPane .banner aside a{left:0px;}

/*Heavy Handed Attempt to allow aside to be edited*/
.rspPane .banner .rdTable .rdCenter span > div div:nth-child(3) {
    right: 300px;
    width: initial!important;
}

/* --------------------
    Start Boxes
-------------------- */

.start-box-base {
    background-color: #ccc;
    height:174px;
    width: 100%;
    position: relative;
}

.start-box-group {
	background-color:#fff;
	border: 1px #ccc solid;
	padding: 0px 25px 0px 25px;
    margin-top: -75px;
    margin-top: -4vw;
}

.start-box {
	height: 170px;
	margin: 4px 5px 7px 5px;
	font-family: @font-primary;
	color:#fff;
	font-size:20px;
	line-height: 24px;
	text-align:center;
	padding:28px 10px;
	display: wrap;
	text-transform:uppercase;
  	justify-content: center;
  	align-items: center;
    background-image:url(../images/bg_waves-lg.png);
	background-repeat:no-repeat;
	background-position:bottom center;
	background-size:100%;
	
	a {
		color:#fff;
        text-decoration: none;
        
        &:hover {
        	color:#fff;
        	text-decoration: none;
        }
	}
}

.start-box-group{
    div:nth-child(1) .start-box{background-color:@color-secondary;}
    div:nth-child(2) .start-box{background-color:mix(@color-secondary, @color-primary);}
    div:nth-child(3) .start-box{background-color:lighten(@color-primary, 10%);}
    div:nth-child(4) .start-box{background-color:@color-primary;}
}

img.s-box {
	margin:12px auto;	
  	display: flex;
  	justify-content: center;
  	align-items: center;
	display:block;
	transition: all .2s ease-in-out;
}

.grow { transition: all .2s ease-in-out; }

.grow:hover { transform: scale(1.1);}

.breadcrumbs {
	background-color: lighten(@color-secondary, 50%);
    padding: 13px 0px;
    margin-bottom: 35px;
}

.breadcrumb-quick-links {
	clear: fixed;
	
	    .row {
	     padding:12px 0;	
     	}

    	.start-box-group {
		background-color: #fff;
        border: 1px #ccc solid;
        padding: 0px 20px 0px 20px;
        margin-top: -55px;
        margin-bottom: -45px;
        }

      .start-box {
		height: 110px;
		margin: 0px 3px 0px 5px;
		font-size: 14px;
		line-height: 16px;
		padding: 5px 5px;
    	}

        .s-box-1 {
	    padding-top: 8px;
        }


        .s-box-3 {
	    padding-top: 10px;
        }

         img.s-box {
       	 margin: 5px auto;
		 justify-content: center;
		 align-items: center;
		 display: block;
		 width: 60%;
         }

}

/* ---------------------
	 Slide Away
--------------------- */
.still-slider {
    min-height: 20px;
    border-top: 1px solid #FFF;
    background: @color-secondary;
}

.slide-away {
    min-height: 20px;
    border-top: 1px solid #FFF;
    position: relative;
    background: @color-secondary;
    background: -moz-linear-gradient(left,  @color-secondary 50%, #cccccc 50%);
    background: -webkit-linear-gradient(left,  @color-secondary 50%,#cccccc 50%);
    background: linear-gradient(to right,  @color-secondary 50%,#cccccc 50%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='@color-secondary', endColorstr='#cccccc',GradientType=1 );

    
    .sa-title, .sa-content {
        width: 50%;
        float: left;
        min-height: 85px;
        padding: 30px 0;
        
        .half-container {
            max-width: @desktop-container / 2;
            padding-left: @container-padding;
            padding-right: @container-padding;
            position: relative;
        }
    }

    .sa-title .half-container {margin-right: 0; margin-left: auto; padding-right: 70px;}
    .sa-content .half-container {margin-left: 0; margin-right: auto;}
    
    .sa-title {
        color: #FFF;
        background: @color-secondary;
        position: relative;
        z-index: 3;
        cursor: pointer;
        &:after {
            position: absolute;
            right: 3%;
            top: 50%;
            .transform(translateY(-50%));
            font-family: FontAwesome;
            content: "\f0da";
            font-size: 105px;
            font-size: 6.5625rem;
            line-height: 0;
            color: #646d77;
            .transition;
        }
        
        &:hover:after {
            color: @color-primary;
        } 
    }
    
    .sa-cover {
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        bottom: 0;
        background: @color-secondary;
        height: 100%;
        width: 50%;
        
        .rspPane & {display: none;}
    }
    
    .sa-content {
        background: #CCC;
    }
}

/*Department Contact Info*/
.dept-contact-info{
    border:1px solid @color-primary;
    padding:20px;
    margin-bottom:30px;
    a{
        text-decoration:none;
    }
    a.ghosted{
        .button {
            opacity: .2;
            cursor: default;
            &:hover {
                background-color: #ffffff;
                color: @color-primary;
            }
        }
    }
    .button:hover {
        background-color: @color-primary;
        color: contrast(@color-primary,#ffffff, #111111);
    }
    
    i{
        width: 30px;
        color: @color-primary;
        font-size: 20px;
    }
    
    span.grade {
        font-family: @font-primary;
        font-weight: normal;
        font-size: 60%;
        float: right;
    }
    
}

/*school page styling*/
span.searchName {
    display: none!important;
}

.dept-phone {
    margin: 5px 0px 20px 0px;
    display: inline-block;
}

.dept-fax {
    margin: 5px 0px 20px 20px;
    display: inline-block;
}

.dept-contact-info a{
    text-decoration:none;
    display: inline-block;
}

.dept-contact-info .button {
    color: inherit;
    background-color: #ffffff;
    border: 1px solid @color-primary;
    border-radius: 0px!important;
    font-weight: 400;
    text-decoration: none;
    margin: 0 auto;
    color: #004a80;
    text-transform: uppercase;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    transition: all 0.2s ease;
    padding: 10px 5%;
    padding: 10px 1.5vw;
    margin-left: 5%;
    margin-left: .55vw;
}

.dept-contact-info .button:hover {
    background-color: #004a80;
    color: #ffffff;
}

.dept-contact-info h3 {
    font-size: 175%;
}

.dept-contact-info i{
    width: 30px; color: @color-primary; font-size: 20px;
}

/* ---------------------
	 Latest
--------------------- */
.latest-container {background: #FFF;}

.latest-news {
    margin: 0 0.25rem 0.3125rem 0;
    padding: 1.25rem 0;
    .icon-title:before {content:"\f09e";}
}

.latest-vids {
    padding: 1.25rem;
    margin: 0 0 0 0.25rem;
    .icon-title:before {content: "\f03d";}
    color: #FFF;
    background: @color-secondary;
    position: relative;
    
    .RadDock_Catapult div.rdContent {
        color: #fff;
    }
    
    .video-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; margin: 0 0 1.25rem;
        iframe { position: absolute; top: 0; left: 0; width: 100% !important; height: 100% !important; outline: none; border: none;}
    }
    
    .video-thumbs 
    {
        margin:0;
        padding: 0;
        
        li {
            display: inline-block;
            list-style: none;
            width: 30%;
            float: left;
            cursor: pointer;
            border: 1px solid lighten(@color-secondary, 15%);
            padding: 0.25rem;
            .transition;
            
            &+li {margin: 0 0 0 5%;}
            
            img {display: block; max-width: 100%; }
            
            &:hover {border: 1px solid @color-primary;}
        }
    }
}

.big-news-snippet { 
    padding: 1.5rem;
    color: #fff;
	background: @color-secondary;
    background: linear-gradient(fade(@color-secondary, 70%), fade(@color-secondary, 70%)), url("../images/banner-2.jpg");
    background-size: cover;
    background-repeat: no-repeat; 
    border: 1px solid #FFF;
    -webkit-box-shadow: 0 0 10px #888;
    -moz-box-shadow: 0 0 10px #888;
    box-shadow: 0 0 10px #888;
    margin-bottom: 1rem;
    
    .news-title { font-size: 32px; font-size: 2rem; font-weight: 600; line-height: 1.2; margin-bottom: 0.25rem; color: inherit; .transition;
        &:hover {color: @color-primary;}
    }
    
    .news-preview { line-height: 1.5; margin: 0; font-size: 18px; font-size: 1.125rem;
        a {color: @color-primary; .transition;
            &:hover { color: #FFF;}
        }
    }
}

.news-snippet { .clearfix;
    .news-thumb { display: block; border: 1px solid #CCC; padding: 2px; background: #FFF; overflow: hidden; float: left; margin-right: 12px; box-sizing: border-box;
        img { max-width: 90px; max-height: 90px; display: block;}
    }
    .news-title { font-size: 22px; font-size: 1.375rem; font-weight: 600; line-height: 1.2; margin-bottom: 0.25rem;}
    .news-preview { line-height: 1.5; margin: 0; font-size: 14px; font-size: 0.875rem;
        a {color: @color-primary; .transition;
            &:hover { color: @color-secondary;}
        }
    }
    
    &+& {border-top: 1px solid #CCC; margin-top: 1rem; padding-top: 1rem;}
}

.small-news-snippet {
    .news-snippet;
    .news-title {font-size: 14px; font-size: 0.875rem;}
    .news-preview {color: #999; font-size: 13px; font-size: 0.8125rem;}
}

.events-mod:before  {
    border-left: 1px solid #ccc;
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    /* width: 0; */
    height: 535px;
    margin: 38px 0;
}


/* ---------------------
	 Dashboard
--------------------- */
a.dashboard-item {
	display: block;
    background: #FFF;
    border: 0.0625rem solid darken(@color-primary, 30%);
    padding: 0.0625rem;
	overflow: hidden;
    border-radius: 0.125rem;
	#shadow > .box-shadow (0, 0, 0.25rem, rgba(0,0,0,0.35));
    opacity: 1;
    .transition;
	
	.image {
		padding-bottom: 56.25%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	
	figcaption {
	    text-align: left;
		padding: 0.25rem;
		color: @color-text;
	}
	
	h3 {
	    margin-top: 0.0625rem;
		border-bottom: 0.0625rem solid #CCC;
		text-transform: uppercase;
		font-size: 0.9125rem;
	}
    
    &:hover {opacity: 0.8;}
}

/* ---------------------
	 Top Announcement
--------------------- */

.top-annoucement {
	background: #fff;
	paddding: 60px;
	text-align: center;
	
	.h3 {
	font-family: @font-primary;
	font-size: 32px;
	color: @color-primary;	
	}
}

/* --------------------_
     News (JJ)
--------------------- */  

.news_cont                              {display: block;padding: 24px 0 58px 0;margin: 0 -17px 0 -12px;}
.img_lft                                {display: inline-block;float: left;padding:0;width: 24%;}
.img_lft img                            { display: block; transition: all 0.5s ease-in-out;-webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;-moz-transition: all 0.5s ease-in-out;}

.annoucement_text                       {display: inline-block;float: left;text-align: left;padding: 8px 0 0 25px;}
.annoucement_text p                     {display: block;padding: 19px 67px 5px 8px;}
.annoucement_text a                     {display: inline-block;margin: 0 0 0 9px;font-family: @font-primary;font-weight: normal;font-style: italic;color: #8a8a8a;
        text-decoration: none;border-bottom: solid 1px #8a8a8a;font-size: 14px;line-height: 14px;position: relative;padding: 0 16px 0 0;}
.annoucement_text a:after               {content: "\f101";position: absolute;top: 0;right: 0;width: 10px;height: 10px;font-family: FontAwesome;}
.news_cont  .btn                        {font-size: 13px;line-height: 23px;border-radius: 0;font-family: @font-primary;font-weight: bold;border: 0;}
.news_cont  .btn:hover                  {background:rgba(22, 164, 185, 0.72); border: 0;}
.annoucement  .btn-primary              {padding: 15px 26px 18px 27px;margin: 0;width: 316px;}
.calender .btn-primary                  {padding: 15px 26px 17px 27px;margin: 0;width: 100%;}
.calender                               {display: inline-block;float: right;width: 28.4%;}
.calender ul                            {display: block;padding: 25px 0 6px 0;}
.calender ul li                         {display: block;padding: 0 0 17px 0;}
.calender_cont                          {display: block;padding: 18px 0 20px 0;background: #fff; transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out;-o-transition: all 0.5s ease-in-out;-moz-transition: all 0.5s ease-in-out;}

.calender_lt                            {display: inline-block;float: left;text-align: center;}
.calender_lt span                       {display: block;font-family: @font-primary;font-weight: normal;font-size: 32px;line-height: 42px;color: #cca46e;
         letter-spacing: 2px;}
.calender_lt small                      {display: block;font-family: @font-primary;font-size: 15px;line-height: 16px;color: #cca46e;text-transform: uppercase;
         letter-spacing: 2px;margin: 0 0 0 0;font-weight: bold;}
.calender_cont p                          {display: inline-block;float: right;font-size: 17px;line-height: 23px;padding: 7px 32px 2px 7px;color: #333333;}


/* ---------------------
	 Info Modules
--------------------- */
.info-modules-container {
    background: @color-secondary;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position:relative;
    
    .overlay {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: fade(@color-primary, 90%);
    }
    
    .sporting-news, .upcoming-events, .on-twitter {
        padding: 20px;
        margin-bottom: 30px;
        background: #FFF;
    }
    
    .sporting-news {
        .icon-title-small:before {content: "\f1b0";} 
    }
    
    .upcoming-events {
        .icon-title-small:before {content: "\f274";}
        
        .event {
            .clearfix;
            position: relative;
            margin-bottom: 0.5625rem;
            
            a {color: @color-primary; .transition;
                &:hover {color: inherit;}
            }
            
            .event-date {
                float: left;
                background: lighten(@color-secondary, 10%);
                color: #FFF;
                font-size: 15px; 
                font-size: 0.9375rem;  
                text-transform: uppercase;
                line-height: 1;
                height: 55px;
                width: 55px;
                text-align: center;
                padding-top: 8px;
                margin: 3px 10px 3px 0;

                span {
                    display: block;
                    font-weight: 700;
                    font-size: 28px;
                    font-size: 1.75rem;
                }
            }

            .event-info {
                margin-left: 65px;
                font-size: 13px;
                font-size: 0.8125rem;

                .event-title {
                    display: block;
                    font-size: 16px;
                    font-size: 1rem;
                    font-weight: 700;
                }
                
                .event-where {display: block;}
            }
        }

        .view-more {
            padding-top: 0.5rem;
            margin-top: 0.75rem;
            &:before {
                display: block;
                content: " ";
                height: 1px; width: 30%;
                border-top: 1px solid @color-primary;
                position: absolute;
                top: 0;
            }
        }  
    }//upcoming-events

    .on-twitter {
        .icon-title-small:before {content: "\f099";}
    }
    
    
    .social-links {
        background: #FFF;
        padding: 10px 10px 6px;
        text-align: center;
        
        .social-text {
            margin-right: 10px;
            font-size: 1rem;
            font-weight: bold;
        }
        
        .social-button {
            padding: 0.125rem 0.6125rem;
            font-weight: bold;
            margin: 0.3125rem 0.1875rem 0.625rem 0.3125rem;
            color: @color-secondary;
            background-color: #bbc0c7;
            display: inline-block;
            -webkit-border-radius: 2px !important;
            -moz-border-radius: 2px !important;
            border-radius: 2px !important;
            letter-spacing: 0;
            font-size: 14px;
            font-size: 0.875rem;
            .transition;
            
            &:hover {
                color: #FFF;
                background: @color-primary;
            }
            
        }
        
    }//social-links
    
    /* -------------------
       Facebook Feed
    --------------------*/

    .fb-feed-header {
        width:580px;
        height: 78px;
        background-color: lighten(@color-secondary, 5%);
    }

    .fb-feed-header-cont {
        background-image: url(../images/icon_fb_feed.png);
        background-repeat: no-repeat;  
        padding-left: 50px;
        font-family: 'Cormorant Garamond', serif;
        font-size: 34px;
        text-transform: uppercase;
        color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
        height: 78px;
        left:24px;
        position: relative;
        padding-top:16px;
    }

    .fb-feed {
        width:580px;
        height: 362px;
        margin: 0;
        border-left: 1px #ccc solid;
        border-right: 1px #ccc solid;
        border-bottom: 1px #ccc solid;
    }

    .fb-feed-wrap {
        width:500px;
        height: 360px;
        margin: 0 auto;
    }

    .fb-feed-cont {
        width:100%;
        height: 360px;
        overflow: hidden;
    }

    .rspPane{//prevents scrollbars inside of the catapult interface
        .fb-feed-wrap,.fb-feed,.fb-feed-header {overflow:  hidden;}    
    }
    
    /* -------------------
       Twitter Feed
    --------------------*/

    .tw-feed-header {
        width:580px;
        height: 78px;
        background-color: lighten(@color-secondary, 5%);
    }

    .tw-feed-header-cont {
        background-image: url(../images/icon_twitter_feed.png);
        background-repeat: no-repeat;  
        padding-left: 94px;
        font-family: 'Cormorant Garamond', serif;
        font-size: 34px;
        text-transform: uppercase;
        color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
        height: 78px;
        left:0px;
        position: relative;
        padding-top:16px;
    }

    .tw-feed {
        width:580px;
        height: 362px;
        margin: 0;
        border-left: 1px #ccc solid;
        border-right: 1px #ccc solid;
        border-bottom: 1px #ccc solid;
    }

    .tw-feed-wrap {
        width:578px;
        height: 360px;
        margin: 0 auto;
    }
    

}//info-modules-container


/* ---------------------
 Content
 --------------------- */
main {
    > .grid{background: #ffffff;}
}

.content {
    position: relative;
    z-index: 0;
    
    .article {
        .search_cont_content {
            .header-search-field {
                background-color: #fff;
                background-image: none;
                border-color: #999;
                border-radius: 7px 0 0 7px;
                border-style: solid;
                border-width: 1px 0 1px 1px;
                box-sizing: border-box;
                color: #53585c;
                float: left;
                height: 35px;
                margin: 0;
                padding: 0 7px;
                vertical-align: top;
                width: 164px;
            }
            .header-search-button {
                box-sizing: border-box;
                color: #555;
                height: 35px;
                margin: 0;
                vertical-align: top;
            }
        }// end search content 
    }
} // end content 


/* ---------------------
 Sidenav
 --------------------- */
 .sidebar {
	margin-top: 0px;	
 }
 
.sidenav {
    border: 0.0625rem solid #ccc;
    background-color: #fff;
    a {color: @color-secondary; .transition;
        &:hover { color: @color-primary;}
    }
    
    .sidenav-title {
        background-color: @color-secondary;
        color: #fff;
        font-size: 20px;
        font-size: 1.25rem;
        text-align: left;
        padding: 0.4375rem 0.625rem;
        text-shadow: none;
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;
        
        i {
            color: rgba(255,255,255,0.45);
            padding-right: 0.375rem;
        }
    }
    
    /* styling to indicate nav depth */
    ul {
        list-style-type: none;
        padding: 0;
        margin:0;
        background: #f5f5f5;
        ul{/*Added shading for more clearly defined heirarchy*/
            background: #e5e5e5;
            ul{
                background: #d5d5d5;

            }
        }
    }
    
    /* All LIs */
    li {
        position:relative;
        border-top: 0.0625rem solid rgba(0,0,0,.2);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        a { 
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            font-weight: normal;
            padding: 0.25rem 0.25rem 0.25rem 1.25rem;
            
            i {font-style: normal !important;}
        }
    }
    
    &>ul>li {
        & > span {display: none !important;}
        & > a {
            font-style: normal !important;
            font-size: 18px;
            font-size: 1.125rem;
        }
    }
    
    .side-active {
        & > a {
            color: contrast(@color-primary,#111111,#ffffff);
            background: @color-primary;
        }
        &>ul {display:block;}
        span.plus, span.minus{color:contrast(@color-primary,#ffffff,#111111);
            &:hover:before{color:contrast(@color-primary,lighten(@color-primary,50%),darken(@color-primary,50%));}
        }
        
        .sidenav-sub span.plus, .sidenav-sub span.minus{color: @color-primary;;
            &:hover:before{color: @color-text;}
        }
        
    }
    
    
    &>ul>li>ul li{
        &>ul {display: none;}
        
        a {
            height:2.5rem; 
            line-height: 2.5rem; 
            padding: 0 0.25rem 0 1.25rem;
        }/*Added Indenting for more clearly defined heirarchy*/
        ul li {
            a {padding: 0 0.25rem 0 2.25rem;}
            
            ul li {
                a {padding: 0 0.25rem 0 3.25rem;}
                }
            
            }
    }
    
    span.plus, span.minus {
        display: block;
        width: 1.25rem;
        text-align: center;
        padding: 0.25rem 0;
        height: 2.5rem; 
        position: absolute;
        top: 0;
        left: 0;
        color: @color-primary;
        font-weight: bold;
        font-size: 22px;
        font-size: 1.375rem;
        cursor: pointer;
        
        &:hover:before {
            color: @color-text;
        }
    }
    
    span.plus {
        line-height: 2rem;
        &:before {content: "+";}
    }
    
    span.minus {
        line-height: 2rem;
        &:before {content: "-";}
    }
    
}//sidenav

/*-----------------------
 Dashboard
------------------------*/

.top-dashboard{
    .dashboard-item:nth-child(1){
        .dashboard-content {background-color:@color-secondary; right:50%;}
        .btn-white:hover{background-color:darken(@color-secondary, 10%);}
        .dashboard-photo {left:50%}
        }
    .dashboard-item:nth-child(2){
        .dashboard-content {background-color:mix(@color-primary, @color-secondary); right:50%;}
        .btn-white:hover{background-color:darken(mix(@color-primary, @color-secondary), 10%);}
        .dashboard-photo {left:50%}
        }
}
.bottom-dashboard{
    .dashboard-item:nth-child(1) .dashboard-content {background-color:lighten(@color-primary, 10%);
    .btn-white:hover{background-color:@color-primary;}
    }
    .dashboard-item:nth-child(2) .dashboard-content {background-color:@color-primary;
    .btn-white:hover{background-color:darken(@color-primary, 10%);}
    }
}

img.s-box {
	margin:12px auto;	
  	display: flex;
  	justify-content: center;
  	align-items: center;
	display:block;	
}

.top-dashboard{
    .dashboard-item:nth-child(1) .dashboard-content .db-events-text {color: contrast(@color-secondary, #ffffff, #111111);}
    .dashboard-item:nth-child(2) .dashboard-content .db-events-text {color: contrast(darken(@color-primary, 10%), #ffffff, #111111);}
}
.bottom-dashboard{
    .dashboard-item:nth-child(1) .dashboard-content .db-events-text {color: contrast(lighten(@color-primary, 10%), #ffffff, #111111);}
    .dashboard-item:nth-child(2) .dashboard-content .db-events-text {color: contrast(@color-primary, #ffffff, #111111);}
}

.db-events-text {
    padding:20px;
    font-family: @font-primary;
    font-weight: 300;
    line-height: 20px;
    font-size: 14px;
    height: 400px;
}

.db-subhdr {
    font-family: @font-primary;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    text-transform: uppercase;
}

.db-event-picture {
    overflow: hidden;
    height: 400px;
    
}

.btn-white {
  font-family: Arial;
  color: contrast(@color-primary, #ffffff, #111111);
  font-size: 14px;
  padding: 12px 40px 12px 40px;
  border: solid contrast(@color-primary, #ffffff, #111111) 1px;
  text-decoration: none;
  word-wrap: break-word;
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
}

.btn-white:hover {
  background: contrast(@color-primary, lighten(@color-primary, 20%), darken(@color-primary, 20%));
  text-decoration: none;
    color: contrast(@color-primary, #ffffff, #111111);
}

.btn-white:visited {
  font-family: Arial;
  color: contrast(@color-primary, #ffffff, #111111);
  font-size: 14px;
  padding: 12px 40px 12px 40px;
  border: solid contrast(@color-primary, #ffffff, #111111) 1px;
  text-decoration: none;
  word-wrap: break-word;
  text-transform: uppercase;
  white-space: nowrap;
  display: inline-block;
  text-decoration: none;
}

.box-padding {
    padding:0;
}
//Dashboard

/* ----------------------
   Points of Pride Banner
------------------------*/   

.pop-banner {
    font-family: @font-primary;
    color: #fff;
    font-size: 14px;
    background-image:url(../images/social-cover.png);
    background-color: @color-navbar;
    background-repeat: no-repeat;
    background-size:115%;
    min-height: 140px;
    padding:18px 40px 0 40px;
}

h3.pop-bnr {
    font-family: @font-secondary;
    color: #fff;
    font-size: 32px; 
    margin:0 0 8px 0;
    text-transform: uppercase;
}

.pop-text{
    font-family: @font-primary;
    color: #fff;
    font-size: 14px;
    width: 600px;
    float: left;
}

.pop-btn {
    float: right;
    margin-top:40px;
}

.pop-video-btn {
    float: left;
    margin-top:40px;
    margin-left: 70px;
}
//Points of Pride Banner

/* ---------------------
	 Footer
	 --------------------- */

.footer h3.title {
    .checkLogo;
}

.footer h3 {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 200%;
    font-weight: normal;
}

.back-to-top {
   background-color: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    bottom: 50px;
    color: #ffffff;
    cursor: pointer;
    display: none;
    font-size: 20px;
    height: 40px;
    left: auto;
    line-height: 40px;
    position: fixed;
    right: 30px;
    text-align: center;
    top: auto;
    width: 40px;
    z-index: 299;
    &:focus {
        outline: none;
    }
    span{color:#000; background: #fff;}
    &:hover {
        color: @color-tertiary;
    }
}

footer {display: block;padding: 25px 15px 25px 15px; background:@color-secondary; font-family: @font-primary; border-top:4px solid @color-line;}

footer ul.information {
    padding: 0px 0px 20px;
}

.footer #quicklinks ul {
	.columns(@width: 50%, @count: 2, @gap: 20px);  /*100%, @count: 1, @gap: 0px*/
	list-style: disc;
	li a {line-height: 1.5; color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%)); }
	li a:hover{color: contrast(@color-secondary, #111, #FFF);}
}

.footer li, .footer a {
    color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
    &:hover{color:contrast(@color-secondary, #ffffff,#111111);}
}

.footer #quicklinks ul {
    padding:0px;
    display: block;
    list-style-type: disc;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
}

.footer #quicklinks ul li {
    list-style-position: inside;
}

.footer h3{
    color: contrast(@color-secondary, #111111, #ffffff);
}

.footer .grid:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    height: 200px;
    width: 200px;
    background-image: url(../images/footer-logo.png);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: top left;
}

.logo-footer img {
    max-width: 240px;
    max-height: 110px;
    padding-top: 12px;
}

ul.footer-social {
  margin: 0px !important;
  padding: 0px;
}

.footer-social li,
.footer-social a {
  color: contrast(@color-secondary, lighten(@color-secondary, 50%), darken(@color-secondary, 50%));
  display: inline;
  list-style: none;
  margin: 0px 5px 0px 0px;
  font-size: 24px;
    &:hover{text-decoration:none;}
}

.social-twitter li,
.social-twitter a {
  font-size: 24px;	
}


.social-facebook li,
 .social-facebook a {
 	font-size: 24px;
 }

 .social-instagram li,
.social-instagram a {
  font-size: 24px;	
}

.social-youtube li,
 .social-youtube a {
 	font-size: 24px;
 }

.social-linkedin li,
 .social-linkedin a {
 	font-size: 24px;
 }

.contact ul.list-unstyled {
    padding-top: 20px;
}

.contact ul.list-unstyled li i {}

.contact ul.list-unstyled li i {
    width: 30px;
    text-align: center;
    padding: 6px;
    margin-right: 8px;
}

.contact a span {
    text-decoration: none;
}

.flogo img {
    width:240px;
    margin: 0px 15px;
}

.flogo a{display: block;}

.flogo a span {
    .checkLogo; //hides the title if the logo contains the name
    text-decoration: none;
}

.catapultLinks {
    display: block;
}


/* ---------------------
	 Copyright
	 --------------------- */

.copyrig {
  display: block;
  padding: 18px 15px 16px 15px;
  background: darken(@color-secondary, 15%);
}

.copyright {
    color: contrast(darken(@color-secondary, 15%), desaturate(lighten(@color-secondary, 50%), 50%), darken(@color-secondary, 50%));
    a {color: contrast(darken(@color-secondary, 15%), #ffffff, #111111); white-space: nowrap;}
    a:active, a:hover {color: mix(@color-link, contrast(@color-secondary, #ffffff, #111111));}
}

/* ---------------------
     Non Descrimination
     --------------------- */
.nondescrimination {
    display: inline-block;
    &:hover{
            a{color: mix(@color-link, contrast(@color-secondary, #ffffff, #111111));}
            i{background: @color-link; color:contrast(@color-link, #111, #FFF);}
        }
    a{display:inline; color:contrast(darken(@color-secondary, 15%), lighten(@color-secondary, 50%), darken(@color-secondary, 50%));}
    i{
        display: inline-block;
        width: 30px;
        height: 30px;
        margin: 10px;
        background: @color-tertiary;
        color: contrast(@color-tertiary, #111, #FFF); 
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        font-weight: bold;
        -webkit-transition: transform 0.1s ease;
        -moz-transition: transform 0.1s ease;
        -ms-transition: transform 0.1s ease;
        -o-transition: transform 0.1s ease;
        transition: transform 0.1s ease;
    }

}

.ada-contrast{
    display: inline-block;
    line-height: 32px;
    font-family:  @font-primary;
    
    a{
    color: #fff;
    line-height:50px;
    padding: 0px 10px;
        &:hover{
            text-decoration: underline;
        }
    }
}



