@media only screen and (max-width: @mobile-breakpoint) {

// ==========================================================================
// Mobile Custom Styles
// ==========================================================================

/****************************
*   BASE
*****************************/
html, body {}
h2{font-size: 100%;}
main {padding-bottom: 0;}
footer{height: auto; position: relative; }
.grid {padding-left: 0px; padding-right: 0px;}

/****************************
*   UNIVERSAL
*****************************/
.media-state {
    z-index: 1; 
}
    
.ada-contrast {
    left: initial;
    right: 10px;
    top: 42px;
    a span{display:inline;}
}

/****************************
*  Header
*****************************/
    
.homepages.non-teachers .header {
  position: relative;
}
    
.header-title .ada-contrast {display: none;}//tablet ADA hidden in mobile
    
.header .header-title .logo img {
    float: none;
}
    
.home-col-contrast {
    position:relative;
    
    .catapultTitle {
        font-size: 40px;
    }
    
    &:before {
        display: none;
    }
}

.home-col-narrow,
.home-col-wide {
    padding-top: 30px;
    padding-bottom: 30px;
    float: none;
    width: 100%;
    /*color: #FFF;
    background-color: @color-body;*/
    
    &:before{background: @color-body;}
    
    & > div {
        max-width: 100%;
        padding: 0 10px;
    }
    
}

.home-col-narrow:before {
    content: "";
    display: block;
    position: absolute;
    background: #fff;
    top: 0;
    left: 0;
    width: 9999px;
    height: 9999px;
}

/*.breadcrumbs {
	display: none;	
}*/

/*.breadcrumbs { text-align: center;
    .col {padding-top: 5px; padding-bottom: 5px; margin: 0;}
    .col:last-child {background:#e2e2e2;}
    .breadcrumb-quick-links {
        text-align: inherit;
    }
}*/


/****************************
*   NAVBAR
*****************************/
.navbar {
    width: 100%;
    height: 45px;
    margin-top:0px;
    position: fixed !important;
    right: 0;
    left: 0;
    top: 0 !important;
    z-index: 10000;
    background: rgba(255, 255, 255, .98);
    border-bottom: 2px solid @color-primary;
    
    /* Web Slide */
    .wsmenu-list  {
        & > li > a {
            color: #333 !important; 
            border-right:0px;
        }
        
        ul li {
            margin: 0px;
            display: block;
            line-height: inherit;
            margin-right: 0px;
            border-bottom: 1px solid rgba(0,0,0,.2);
            a {
                height: 43px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                color: #111 !important;
            }   
        }
        
        .wsmenu-click{width:43px;} 
        
        ul ul {border-left: none !important; border-top:1px solid rgba(0,0,0,.2);}
        
        ul ul li:first-child:before  {display: none;}
        ul ul li:last-child  {border: none;}
        
    }
    
    .wsmenu {
        height: 100%;
        font-family: @font-primary;
        text-transform: uppercase;
        font-weight: normal;
        &-list > li {
        	border-right: 0px #999 solid;
        }
    }    
    
    .mobile-navbar-button {     
        color: @color-secondary;
        font-size: 1.35rem;
        line-height: 42px;
        float: right;
        margin-right: 10px;
        top: 3px;
        position: relative;
        margin-left: 10px;
        
        &:before {
            content:" ";
            height: 80%;
            width: 1px;
            background: @color-tertiary;
            position: absolute;
            left: -10px;
            top: 4px;
        }
    }
    
    .mobile-logo {
        max-width: 155px;
        width: 100%;
        position: relative;
        left: 60px;
    }
    
    .animated-arrow span, .animated-arrow span:before, .animated-arrow span:after {
        background: @color-tertiary;
    }
    
    .animated-arrow.menuopen span {
        background-color: transparent;
    }
    
    .search { margin-left: 0;
        & > a {
            & > i {
                color: @color-tertiary;
                font-size: 1.75rem;
                float: right;
                margin-right: 10px;
                top: 10px;
                position: relative;
                z-index:-1;
            }
            & > span {display: none;}
        }
        
        #m3 {
            border-color: #FFF;
            border-top-right-radius: 0;
            &:before{
            }
        }
    }
}

    .wsmenu-list ul li:hover ul, .wsmenu-list ul ul li:hover ul{padding:0px;}

    .sticky .wsmenu ul {top: 0px;}//nav-fix for override.

.header-quicklinks{
    #m2{max-height:390px; overflow: auto;}
    
    .util{
        float: none;
        display: block;
        text-align: center;
    }
    
    .dropdown{
        display: block;
        text-align: center;
    }
    
}

/****************************
*   HEADER
*****************************/
.header {
    margin-top:42px;
    padding-bottom: 20px;
    
    ul {
        .list-unstyled;
        li {
            display: inline-block;
            line-height: 50px;
            margin-right: 15px;
            .phone-main {
                text-decoration: underline;
            }
        }
        a {
            color: contrast(#ffffff, #ffffff, @color-primary);
            font-weight: 600;
            text-align: center;
            .transition;
            i {
                 color: contrast(#ffffff, #ffffff, @color-primary);
                .transition;
            }
            
            &:hover {
                 color: contrast(#ffffff, #ffffff, @color-link);
                i {
                     color: contrast(#ffffff, #ffffff, @color-link);
                }
            }
        } 
    }
    
}

.header .header-title .logo img {
    max-height: 140px;
    max-width: 200px;
    margin: 0px 28px;
}



/****************************
*   TOP BAR
*****************************/
.top-bar { 
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
    margin-top: 48px;
  
    .search {display: none;}
    
    .top-bar-social {
        display: none;
    }
    
}

.m-tb-social-links {
   margin-top:42px; 
   right: -10px;	
}

.m-tb-quicklinks {
   margin-top:-10px !important;	
}


/****************************
*   TITLE BAR
*****************************/
.title-bar {
    position:relative;
    min-height: 100px;
    height: auto;
       
    .logo,
    .title-links {
        position: relative;
        width: auto;
        height: auto;
        z-index: 10;
        display: none;
        
        a {
            position:relative;
            border: none;
            outline: none;
            z-index: 4; 
            top:auto;
            .transform(none);
        }
    }   
    
     .logo {
        left: 0;
        
        a { display: block; width: 105px; margin: auto;}
        a img {
            display: block; 
            max-width: 105px;
            max-height:105px;
            width: auto;
            height: auto;
        }
    }
    
    .title-links {
        right: auto;
        text-align:center;
        
        & > a {
            display: block;
            color: #FFF;
            background: transparent;
            height: auto;
            width: 100%;
            border-radius: 0;
            text-align: center;
            font-size: 0.8125rem;
            line-height: @line-height;
            text-decoration: underline;
            
            &:hover {
                background: transparent;
                color: #FFF;
                .transform(none);

                span {
                    display: block;
                    position: static;
                    top: 100%; 
                    font-size: 0.8125rem;
                    line-height: inherit;
                    color: inherit;
                    white-space:normal;
                    right: auto;
                    background: transparent;
                    padding: 0;
                    border-radius: 0;
                    margin-top: 0;
                    font-family:  inherit;
                    letter-spacing: normal;
                    -webkit-animation: none;
                            animation: none;
                    opacity: 1;
                    
                    
                    &:before {
                        display: none; 
                    }
                }
            }
            
            i {display: none;}
            span {display: block;}
            
        }
        a+a{margin-left: 0;}
        
    }
    
    .site-title {
        position:relative;
        height:auto;
        padding-left: 0;
        padding-right: 0;
        margin-top: 2%;

        h1 {
            top:auto;
            .transform(none);
            a {font-size: 60px;
            
                span {margin-top: 0;}
            }
            
        }   
    }
}

.header {
    .header-title {max-width:  100%;
        .logo {width:100%; text-align: center; float:none;}
        .title {float:  none;}
    }
}

/****************************
*   QUICK LINKS
*****************************/
.mobile-quick-links {
    display: block;
    /*margin-right: -4%; Causes issues in mobile*/
}

.breadcrumb-quick-links{
    
    img.s-box {
        width: 45%;
    }

    .start-box {
        height: 90px;
        margin: 4px 5px 10px 5px;
        font-size: 14px;
        line-height: 16px;
        padding: 5px 5px;
    }
 
}
    
/****************************
*   CONTENT AREA
*****************************/

.primary-quick-links { display: none; }

.link-tiles {
    border-top: 1px solid #DDD;  
    
    ul.tiles {
        height: auto;
        
        li {
            width: 50%;
            height: 130px;
            float:left;

            a {
                font-size: 16px;

                &:hover {
                    div {
                        .transform(none);
                    }
                }       

                span {
                    position: absolute; 
                    bottom: 5px;
                    left: 5px;
                    line-height: 1.3;
                }
            }
        }
    }
}

.events-mod:before {
 display: none;	
}

.start-box {
    margin: 4px 5px 20px 5px;
}

.s-box-2 {
    padding-top: 10px;
}

.s-box-4 {
    padding-top: 10px;
}

/*School & Department Styling*/
.dept-phone {
    margin: 5px;
    display: block;
}

.dept-fax {
    margin: 5px;
    display: block;
}
    
/*-----------------
POP Banner 
-------------------*/

.pop-banner {
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-size: 14px;
    /*background-image: url(../images/bg_pop_m-wave.jpg);
    background-repeat: no-repeat;*/
    background: @color-primary;
    background-size: 100%;
    background-position: center bottom;
    height: auto;
    padding: 18px 40px 0 40px;
    margin: 30px 0;
}

.pop-text {
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-size: 14px;
    width: 100%;
    float: left;
}

.pop-btn {
    float: left;
    margin: 30px 0px;
}

.pop-video-btn {
    float: left;
    margin-top: 30px;
    margin-left: 0;
}


.db-events-text {
    height: auto;
}

.db-event-picture {
    background-image: url(../images/bg_db_m-image.jpg);
    background-position: left bottom;
    width: 100%;
}

/*-------------------
Social Feeds
-------------------*/


.fb-feed-header-cont {
    background-image: url(../images/icon_fb_feed.png);
    background-repeat: no-repeat;
    padding-left: 50px;
    font-family: 'Cormorant Garamond', Arial, sans-serif, serif;
    font-size: 24px !important;
    text-transform: uppercase;
    color: #fff;
    height: 78px;
    left: 24px;
    position: relative;
    padding-top: 21px !important;
}

.fb-feed {
    width:580px;
    height: 362px;
    border-left: 1px #ccc solid;
    border-right: 1px #ccc solid;
    border-bottom: 1px #ccc solid;
}

.tw-feed-header {
	margin-top: 20px;
    width:580px;
    height: 78px;
    background-color: #949599;
}

.tw-feed-header-cont {
    background-image: url(../images/icon_twitter_feed.png);
    background-repeat: no-repeat;
    padding-left: 82px !important;
    font-family: 'Cormorant Garamond', Arial, sans-serif, serif;
    font-size: 24px !important;
    text-transform: uppercase;
    color: #fff;
    height: 78px;
    left: 0px;
    position: relative;
    padding-top: 21px !important;
}

/*Dasboard*/
.top-dashboard{
    .dashboard-item:nth-child(1){
        .dashboard-content {right:unset;}
        .dashboard-photo {left:unset;}
        }
    .dashboard-item:nth-child(2){
        .dashboard-content {right:unset;}
        .dashboard-photo {left:unset;}
        }
}

/****************************
*   FOOTER
*****************************/

.footer #quicklinks ul {
	.columns(@width: 100%, @count: 1, @gap: 0px); /* 100%, @count: 1, @gap: 0px */
	list-style: disc;
	li a {line-height: 1.5; color: fade(contrast(@color-primary, #111, #FFF), 70%);}
	li a:hover{color: contrast(@color-primary, #111, #FFF);}
}
    
.footer-content {
    background: #333;
    padding: 25px 0; 
    font-size: 0.8125rem;
    text-align: center;

    a {color: inherit;}

    [class^='f-'] {
        display: inline-block;
        width: 48%;
        min-width: 200px;
        padding-left: 24px;
        padding-bottom: 8px;
        padding-top: 8px;

        &+[class^='f-'] {margin-left: 0;}

    }

    .f-logo { padding-left: 0 !important;  display: block; border-bottom: 1px solid #444; width: 100%;
        img {
        	max-width: 130px; width: auto;}
    }   
    /*
    .f-location:before,
    .f-email:before,
    .f-phone:before,
    .f-link:before {display: none;}    */
}

.flogo img {
    margin: 0px 8px 0px;
}

ul.footer-social {
    margin: 12px 8px 0px !important;
    padding: 0px;
}

.copyright .catapult {
    font-size: 10px;
    font-size: 0.625rem;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    float: left;
    text-align: center !important;
    margin-left: 1.5em;
}

.nondescrimination {
	line-height: 
	display: inline;
}
    
/*End Media Query*/
}