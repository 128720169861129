// ==========================================================================
// Framework Variables
// ==========================================================================

@color-container: #fff;

// Row Sizing
@row-xs:		            .4em;
@row-s:			            .6em;
@row-m:			            1em;
@row-l:			            2em;
@row-xl:		            3em;
@row-xxl:		            4em;

// Margin Sizing
@margin-2px					0.125em;

// Header Logo
@header-logo-width:			180px;

// Container Sizing
@container-padding: 		20px;

// Banner
@banner-height: 			780px;
@banner-height-subpage:		250px;
@banner-height-mobile:		350px;
@banner-undertuck:			0px;

// Container Widths
@mobile-container:          100%;
@tablet-container:   	    768px;
@desktop-container:  	    1200px;

// Resonsive Breakpoints
@mobile-breakpoint:  	    (@tablet-container - 1px);
@tablet-breakpoint:  	    (@mobile-breakpoint + 1px);
@desktop-breakpoint: 	    1025px;