@media only screen and (min-width: @tablet-breakpoint) and (max-width: (@desktop-breakpoint - 1px)) {

// ==========================================================================
// Tablet Custom Styles
// ==========================================================================

/*---------------------------
  Topbar Links
--------------------------*/

.header-quicklinks .ada-contrast {
    display: none;
}
    
.header-quicklinks .search-bar {
    background: lighten(@color-primary, 10%);
    position: relative;
    float: right;
    line-height: 50px;
    z-index: 99;
    width: 100%;
}

.header-quicklinks-mobile {
}

.header-quicklinks ul {
    margin: 0 0 -50px -30px;
    list-style: none;
    height: 50px;
}

.header-quicklinks ul li {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -4px;
}

.header-quicklinks .dropdown > li a {
    padding: 0px;
    margin: 0 -6px 0 0px;
    display: inherit;
    color: contrast(@color-primary, #ffffff, #111111);
    /*color: rgba(255, 255, 255, 0.7);*/
}

.header-quicklinks .dropdown {
    display: inline-block;
    margin: 0px 0px 0px 5px;
}

    span.divider-no-margin {
    color: rgba(255, 255, 255, 0.3);
    margin: 0 5px 0 -20px;
}

.header-quicklinks .util i {
    font-size: 20px;
    margin-right: -2px;
}
  
/*ADA Tools*/

.header-title .ada-contrast {
    display:  block;    
    left: 5px;
    top:0px;
    a {color:@color-primary;}
}

/*Logo Adjustment for ADA*/
    
.header{
    .header-title{
        .logo{padding-top:30px;}
    }
}
    
/****************************
*  MENU
*****************************/

/*.navbar {
    position: relative;
    width: 100%;
    float: right;
    border-radius: 0px !important;
    z-index: 20;
    margin-top: 34px;
    height:65px;
}

.wsmenu-list {
        text-align: left;
        margin: 0 auto 0 auto;
        width: 100%;
        display: table;
        padding: 0px;
        & > li {
            text-align: center;
            display: table-cell;
            & > a {
                display: block;
                color: @color-navbar-text;
                padding: 0px 14px;
                line-height: 13px;
                border-right: 1px solid rgba(0,0,0,0.5);
                text-decoration: none;
                position: relative;
                white-space: nowrap;
                .fa {
                    display: inline-block;
                    font-size: 14px;
                    line-height: 0;
                    margin-right: 0px;
                }
            }
            & > a:hover, & > a.active {
                text-decoration: none;
                color: @color-secondary;
            }
        }
}*/

/****************************
*  Header
*****************************/
    
.homepages.non-teachers .header {
  position: relative;
  width: 100%;
  z-index: 12;
}

/****************************
*  Navigation
*****************************/
    
.wsmenu {
    font-size: 95%;
}

/****************************
*   UNIVERSAL
*****************************/
.media-state {
    z-index: 2; 
}


.home-col-contrast {
    position:relative;
    
    .catapultTitle {
        font-size: 40px;
    }
    
    &:before {
        width: 50%;
    }
}

.home-col-wide {
    width: 50%;
   
    & > div {
        max-width: @max-content-width * 0.50;
        padding: 0 40px;
    }
}

.home-col-narrow {
    width: 50%;
    
    & > div {
        max-width: @max-content-width * 0.50;

        padding: 0 40px;
    }
}

/*.breadcrumbs { text-align: center;
    .col {padding-top: 5px; padding-bottom: 5px; margin: 0;}
    .col:last-child {background:#e2e2e2;}
    .breadcrumb-quick-links {
        text-align: inherit;
    }
}*/

/****************************
*   TITLE BAR
*****************************/
.title-bar { 
    .logo a img {max-width: 130px;} 
}

/****************************
*   BANNER
*****************************/
.ccms-banner-overlay {
    position: absolute;
    color: #FFF;
    max-width: 45% !important;
    margin-left: 0px !important;
    top: 35%;
    padding: 0;
    text-align: left;
    z-index: 11;
    text-transform: uppercase;
    font-size: 1.5rem;
}

/*-------------------
Announcements
--------------------*/

#catapultannounce-content a.all-catapultannounce-link {
    color: @color-primary;
    border: 1px solid @color-primary;
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
    border-radius: 0px !important;
    margin: 0px 0px -40px 0px;
    padding: 10px 20px !important;
    text-transform: uppercase;
}

/*-----------------
POP Banner 
-------------------*/

.pop-banner {
    font-family: @font-primary;
    color: #fff;
    font-size: 14px;
    background-image: url(../images/bg_pop_m-wave.jpg);
    background-repeat: no-repeat;
    /*background: @color-primary;*/
    background-size: 100%;
    background-position: center bottom;
    height: 215px;
    padding: 18px 40px 0 40px;
    margin: 30px 0;
}

.pop-text {
    font-family: @font-primary;
    color: #fff;
    font-size: 14px;
    width: 100%;
    float: left;
}

.pop-btn {
    float: left;
    margin-top: 40px;
}

.pop-video-btn {
    float: left;
    margin-top: 40px;
    margin-left: 0;
    margin-right: 30px;
}

/****************************
*   CONTENT AREA
*****************************/

.events-mod:before {
 display: none;	
}

.start-box {
    margin: 4px 5px 20px 5px;
}

/*Dashboard*/
.db-event-picture {
    background-image: url(../images/bg_db_m-image.jpg);
    background-position: left bottom;
    width: 100%;
}

.db-event-picture {
    background-image: none;
}

.top-dashboard .dashboard-item:nth-child(2) .dashboard-content {
    right: 0%;
}

.top-dashboard .dashboard-item:nth-child(2) .dashboard-photo {
    left: 0%;
}

.bottom-dashboard .dashboard-item:nth-child(2) .dashboard-content {
    right: 50%;
}

.bottom-dashboard .dashboard-item:nth-child(2) .dashboard-photo {
    left: 50%;
}
    
/****************************
*   FOOTER
*****************************/
   
.logo-footer img {
    padding: 12px 10px 0 0;
    max-width: 200px;
}   
    
.footer #quicklinks ul {
	.columns(@width: 100%, @count: 1, @gap: 0px); /* 100%, @count: 1, @gap: 0px */
	list-style: disc;
	li a {line-height: 1.5; color: fade(contrast(@color-primary, #111, #FFF), 70%);}
	li a:hover{color: contrast(@color-primary, #111, #FFF);}
}
    
    .footer-content {
        background: #333;
        padding: 25px 0; 
        font-size: 0.8125rem;
        font-weight: 300;
        
        a {color: inherit;}
        
        [class^='f-'] {
            width: 50%;
            margin-left: 0;
            float: left;
            padding-left: 40px;
            padding-bottom: 8px;
            padding-top: 8px;
            border-bottom: 1px solid #444; 
            &+[class^='f-'] {margin-left: 50%;}
        }
        
        .f-logo { padding-left: 0 !important; text-align: center; width: 50%; position: absolute; left: 0; border-bottom: none; top: 50%; .transform(translateY(-50%));
            img {max-width: 130px; width: auto;}
        }   
    }
  
    ul.footer-social {
    padding: 0px 0px 15px 10px;
} 
    
/*End Media Query*/
}