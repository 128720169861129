// ==========================================================================
// Modify
// ==========================================================================
.catapultannounce-popup .catapultannounce-title-row .catapultannounce-title h2 {
    float: left;
    font-size: 150%;
    font-family: 'Lato', san-serif;
    color: #004a80;
}

.catapultannounce-popup .catapultannounce-title-row h2 {
    float: left;
    font-size: 150% !important;
    font-family: 'Lato', san-serif;
    color: #004a80;
}